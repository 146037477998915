<template>
  <b-card-code v-hotkey="keymap">
    <div class="d-flex justify-content-between">
      <h4 class="text-capitalize">{{ form.name }}</h4>
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <b-modal
      ref="edit_step_modal"
      hide-footer
      title="Edit Step"
      centered
      header-close-variant="danger"
      size="lg"
    >
      <validation-observer ref="EditStepsAttachmentForm" #default="{ invalid }">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="updateStepsAttachment(selectedStep)"
        >
          <b-form-group label="Step Name">
            <template #label>
              <span>Step Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="description"
              rules="required"
            >
              <!-- <b-form-textarea
                name="description"
                v-model="selectedStep.title"
                :state="errors.length > 0 ? false : null"
                placeholder="A brief description about the evidence"
              ></b-form-textarea> -->
              <quill-editor
                v-model="selectedStep.title"
                :options="editorOptionEditSteps"
                :state="errors.length > 0 ? false : null"
              >
                <div id="toolbarEditSteps" slot="toolbar">
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--Upload File -->
          <div
            class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
            v-if="attachment_steps_file"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100 mb-50"
            >
              <p class="font-weight-bold">Selected file:</p>
              <b-button
                @click="removeSelectedImage"
                variant="outline-danger"
                size="sm"
                ><feather-icon icon="XIcon" class="mr-50" /><span
                  class="align-middle"
                  >Remove Selected</span
                ></b-button
              >
            </div>

            <div
              class="w-100"
              style="overflow: hidden; height: 220px; cursor: grab"
            >
              <panZoom
                :options="{ minZoom: 0.5, maxScale: 5 }"
                selector=".zoomable"
              >
                <div class="zoomable">
                  <img
                    height="100%"
                    width="100%"
                    :ref="selectedStep.filename"
                    :src="convertFileToImage(attachment_steps_file)"
                    :alt="selectedStep.caption"
                  />
                </div>
              </panZoom>
            </div>
            <p class="mt-25 font-weight-bold">
              {{ attachment_steps_file.name }}
            </p>
          </div>
          <b-form-group label="Select Image File">
            <template #label>
              <span>Select Image File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="ext:png,jpg,jpeg"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-file
                    v-model="attachment_steps_file"
                    :state="Boolean(attachment_steps_file)"
                    ref="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    v-on:change="handleFileUpload()"
                  ></b-form-file>
                </div>
                <div class="col-6">
                  <b-button
                    @click="handlePasteImageToClipboardForEditStep"
                    variant="outline-primary"
                    ><feather-icon icon="CopyIcon" class="mr-50" /><span
                      class="align-middle"
                      >Paste from Clipboard</span
                    ></b-button
                  >
                </div>
              </div>

              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <div class="mt-3">
                Selected file:
                {{
                  attachment_steps_file
                  ? attachment_steps_file.name
                  : ""
                }}
              </div> -->
            </validation-provider>
          </b-form-group>
          <b-form-group label="Caption">
            <template #label>
              <span>Caption</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Caption"
              vid="caption"
              rules="required"
            >
              <b-form-input
                v-model="selectedStep.caption"
                :state="errors.length > 0 ? false : null"
                name="caption"
                placeholder="A caption for the evidence"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              type="submit"
              variant="success"
              :disabled="invalid"
              class="my-1"
              size="md"
            >
              <span v-if="isloadingUpdate"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else
                ><feather-icon icon="CheckIcon" class="mr-50" /><span
                  class="align-middle"
                  >Update</span
                ></span
              >
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      ref="edit_retest_step_modal"
      hide-footer
      title="Edit Step"
      centered
      header-close-variant="danger"
      size="lg"
    >
      <validation-observer ref="EditStepsAttachmentForm" #default="{ invalid }">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="updateRetestAttachment(selectedRetestStep)"
        >
          <b-form-group label="Step Name">
            <template #label>
              <span>Step Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="description"
              rules="required"
            >
              <quill-editor
                v-model="selectedRetestStep.description"
                :options="editorOptionEditRetest"
                :state="errors.length > 0 ? false : null"
              >
                <div id="toolbarEditRetest" slot="toolbar">
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <!-- <b-form-textarea
                name="description"
                v-model="selectedRetestStep.description"
                :state="errors.length > 0 ? false : null"
                placeholder="A brief description about the evidence"
              ></b-form-textarea> -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--Upload File -->
          <div
            class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
            v-if="attachment_steps_file"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100 mb-50"
            >
              <p class="font-weight-bold">Selected file:</p>
              <b-button
                @click="removeSelectedImage"
                variant="outline-danger"
                size="sm"
                ><feather-icon icon="XIcon" class="mr-50" /><span
                  class="align-middle"
                  >Remove Selected</span
                ></b-button
              >
            </div>

            <div
              class="w-100"
              style="overflow: hidden; height: 220px; cursor: grab"
            >
              <panZoom
                :options="{ minZoom: 0.5, maxScale: 5 }"
                selector=".zoomable"
              >
                <div class="zoomable">
                  <img
                    height="100%"
                    width="100%"
                    :ref="selectedRetestStep.filename"
                    :src="convertFileToImage(attachment_steps_file)"
                    :alt="selectedRetestStep.caption"
                  />
                </div>
              </panZoom>
            </div>
            <p class="mt-25 font-weight-bold">
              {{ attachment_steps_file.name }}
            </p>
          </div>
          <b-form-group label="Select Image File">
            <template #label>
              <span>Select Image File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="ext:png,jpg,jpeg"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-file
                    v-model="attachment_steps_file"
                    :state="Boolean(attachment_steps_file)"
                    ref="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    v-on:change="handleFileUpload()"
                  ></b-form-file>
                </div>
                <div class="col-6">
                  <b-button
                    @click="handlePasteImageToClipboardForEditStep"
                    variant="outline-primary"
                    ><feather-icon icon="CopyIcon" class="mr-50" /><span
                      class="align-middle"
                      >Paste from Clipboard</span
                    ></b-button
                  >
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <div class="mt-3">
                Selected file:
                {{
                  attachment_steps_file
                  ? attachment_steps_file.name
                  : ""
                }}
              </div> -->
            </validation-provider>
          </b-form-group>
          <b-form-group label="Caption">
            <template #label>
              <span>Caption</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Caption"
              vid="caption"
              rules="required"
            >
              <b-form-input
                v-model="selectedRetestStep.caption"
                :state="errors.length > 0 ? false : null"
                name="caption"
                placeholder="A caption for the evidence"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              type="submit"
              variant="success"
              :disabled="invalid"
              class="my-1"
              size="md"
            >
              <span v-if="isloadingUpdate"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else
                ><feather-icon icon="CheckIcon" class="mr-50" /><span
                  class="align-middle"
                  >Update</span
                ></span
              >
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-tabs>
      <!-- general tab -->
      <b-tab title="Details">
        <validation-observer ref="VulnUpdateForm" #default="{ invalid }">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="updateVulnerability"
          >
            <b-form-group label="Name" label-for="Name">
              <template #label>
                <span>Name</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="form.name"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!is_staff"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="" label-for="Is Verified?">
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.is_verified"
                name="is_verified"
                :value="true"
                :unchecked-value="false"
              >
                Is Verified?
              </b-form-checkbox>
            </b-form-group> -->
            <b-form-group label="" label-for="Is Exploitted?">
              <b-form-checkbox
                id="checkbox-2"
                v-model="form.is_exploited"
                name="is_exploited"
                :value="true"
                :unchecked-value="false"
                :disabled="!is_staff"
              >
                Is Exploited?
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Synopsis" label-for="synopsis">
              <quill-editor
                v-model="form.synopsis"
                :options="editorOptionSynopsis"
                :disabled="!is_staff"
                :style="{ opacity: is_staff ? 1 : 0.5 }"
              >
                <div id="toolbarSynopsis" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <!-- <b-form-textarea v-model="form.synopsis"></b-form-textarea> -->
            </b-form-group>
            <b-form-group label="Description" label-for="Description">
              <template #label>
                <span>Description</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <quill-editor
                  v-model="form.description"
                  :options="editorOption"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!is_staff"
                  :style="{ opacity: is_staff ? 1 : 0.5 }"
                >
                  <div id="toolbar" slot="toolbar">
                    <button class="ql-bold">Bold</button>
                    <button class="ql-italic">Italic</button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-list" value="ordered"></button>
                  </div>
                </quill-editor>
                <!-- <b-form-textarea
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Severity" label-for="Severity">
              <template #label>
                <span>Severity</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="severity"
                rules="required"
              >
                <b-form-select
                  v-model="form.severity"
                  :options="severity_options"
                  :state="errors.length > 0 ? false : null"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="CVSS Score" label-for="cvss_score">
              <b-form-input
                id="cvss_score"
                v-model="form.cvss_score"
                name="cvss_score"
                :disabled="!is_staff"
              />
            </b-form-group>

            <b-form-group label="OWASP Category" label-for="owasp_category">
              <v-select
                v-model="form.owasp_categories"
                multiple
                label="text"
                :options="owasp_options"
                @search="fetchOwaspOptions"
                autocomplete
                :disabled="!is_staff"
              />
            </b-form-group>

            <b-form-group label="CVE" label-for="CVE">
              <b-form-textarea
                v-model="form.cve"
                :disabled="!is_staff"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Path" label-for="Path">
              <b-form-input
                v-model="form.path"
                :disabled="!is_staff"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Parameter" label-for="Parameter">
              <b-form-input
                v-model="form.parameter"
                :disabled="!is_staff"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Port" label-for="port">
              <!-- <validation-provider
                #default="{ errors }"
                name="port"
                rules="required"
              > -->
              <b-form-input
                id="port"
                v-model="form.port"
                name="port"
                :disabled="!is_staff"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>

            <b-form-group label="Protocol" label-for="Protocol">
              <!-- <validation-provider
                #default="{ errors }"
                name="Protocol"
                rules="required"
              > -->
              <b-form-select
                v-model="form.protocol"
                :options="protocol_options"
                :disabled="!is_staff"
              ></b-form-select>
              <!-- :state="errors.length > 0 ? false : null" -->
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>

            <b-form-group label="Recommendations" label-for="recommendation">
              <quill-editor
                v-model="form.recommendation"
                :options="editorOptionRecommendation"
                :disabled="!is_staff"
                :style="{ opacity: is_staff ? 1 : 0.5 }"
              >
                <div id="toolbarRecommendation" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <!-- <b-form-textarea v-model="form.recommendation"></b-form-textarea> -->
            </b-form-group>
            <b-form-group label="References" label-for="references">
              <quill-editor
                v-model="form.references"
                :options="editorOptionReferences"
                :disabled="!is_staff"
                :style="{ opacity: is_staff ? 1 : 0.5 }"
              >
                <div id="toolbarReferences" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <!-- <b-form-textarea v-model="form.references"></b-form-textarea> -->
            </b-form-group>
            <!-- <b-form-group label="Evidence" label-for="nessus_evidence">
              <b-form-textarea v-model="form.nessus_evidence"></b-form-textarea>
            </b-form-group> -->

            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid"
              class="my-1"
              size="sm"
            >
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Update</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab title="Steps to Reproduce" active>
        <div>
          <draggable
            :list="steps_to_reproduce"
            tag="ul"
            class="list-group list-group-flush cursor-move"
            @change="handleDetectDrag"
          >
            <transition-group type="transition" name="flip-list">
              <b-list-group-item
                tag="li"
                class="my-1 p-0"
                v-for="listStepsItem in steps_to_reproduce"
                :key="listStepsItem.order_id"
              >
                <StepToReproduceCard
                  :step="listStepsItem"
                  :steps_files="steps_files"
                  :editSteps="editSteps"
                  :gotoDelete="gotoDelete"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
        </div>
        <b-card-code
          title="Add Screenshot"
          class="mt-1 card shadow-none bg-transparent border-primary"
        >
          <div class="">
            <!-- <div>
              <h4>Add Screenshot</h4>
              
            </div> -->
            <validation-observer ref="AddAttachmentForm" #default="{ invalid }">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="addAttachment"
              >
                <b-form-group label="Steps" label-for="description">
                  <template #label>
                    <span>Steps</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Steps"
                    vid="Steps"
                    rules="required"
                  >
                    <quill-editor
                      v-model="description"
                      :options="editorOptionSteps"
                    >
                      <div id="toolbarSteps" slot="toolbar">
                        <!-- <button class="ql-bold">Bold</button>
                          <button class="ql-italic">Italic</button> -->
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-list" value="ordered"></button>
                      </div>
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!--Upload File -->
                <!-- :state="Boolean(attachment_file)" -->
                <div
                  class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
                  v-if="attachment_file"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-100 mb-50"
                  >
                    <p class="font-weight-bold">Selected file:</p>
                    <b-button
                      @click="removeSelectedImageFromAddSteps"
                      variant="outline-danger"
                      size="sm"
                      ><feather-icon icon="XIcon" class="mr-50" /><span
                        class="align-middle"
                        >Remove Selected</span
                      ></b-button
                    >
                  </div>

                  <div
                    class="w-100"
                    style="overflow: hidden; height: 220px; cursor: grab"
                  >
                    <panZoom
                      :options="{ minZoom: 0.5, maxScale: 5 }"
                      selector=".zoomable"
                    >
                      <div class="zoomable">
                        <img
                          height="100%"
                          width="100%"
                          :src="convertFileToImage(attachment_file)"
                          :alt="attachment_file.name"
                        />
                      </div>
                    </panZoom>
                  </div>
                  <p class="mt-25 font-weight-bold">
                    {{ attachment_file.name }}
                  </p>
                </div>
                <b-form-group label="Screenshot" label-for="File">
                  <template #label>
                    <span>Screenshot</span>
                    <!-- <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    > -->
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="file"
                    rules="ext:png,jpg,jpeg"
                  >
                    <div class="row">
                      <div class="col-8">
                        <b-form-file
                          v-model="attachment_file"
                          ref="file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          v-on:change="handleFileUpload()"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="col-4">
                        <b-button
                          block
                          @click="handlePasteImageToClipboardForAddStep"
                          variant="outline-primary"
                          ><feather-icon icon="CopyIcon" class="mr-50" /><span
                            class="align-middle"
                            >Paste from Clipboard</span
                          ></b-button
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>

                    <!-- <div class="mt-3">
                      Selected file:
                      {{ attachment_file ? attachment_file.name : "" }}
                    </div> -->
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Caption" label-for="caption">
                  <template #label>
                    <span>Caption</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Caption"
                    vid="caption"
                    rules="required"
                  >
                    <b-form-input
                      v-model="caption"
                      name="caption"
                      placeholder="A caption for the evidence"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="description == '' || invalid"
                  class="my-1"
                  size="sm"
                >
                  <span v-if="isloadingAttach"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>Attach</span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card-code>
        <b-card-code
          title="Evidence Notes"
          class="mt-1 card shadow-none bg-transparent border-primary"
        >
          <div class="mt-2">
            <!-- <div class="my-1">
              <h4>Evidence Notes</h4>
            </div> -->
            <b-form-group label="Evidence" label-for="nessus_evidence">
              <quill-editor
                v-model="form.nessus_evidence"
                :options="editorOptionEvidence"
              >
                <div id="toolbarEvidence" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-list" value="ordered"></button>
                </div>
              </quill-editor>
              <!-- <b-form-textarea v-model="form.nessus_evidence"></b-form-textarea> -->
            </b-form-group>
            <b-button
              class="my-1"
              size="sm"
              type="submit"
              variant="primary"
              @click="updateEvidence()"
              :disabled="disable || form.nessus_evidence === ''"
            >
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else> Update Notes</span>
            </b-button>
          </div>
        </b-card-code>
      </b-tab>
      <b-tab title="Retest Evidences" lazy>
        <!-- <ul id="example-1">
          <li v-for="item in attached_files" :key="item.filename">
            {{ item.filename }}
          </li>
        </ul> -->
        <div
          v-for="(listItem, index) in retest_evidence"
          :key="listItem.filename"
        >
          <b-list-group-item tag="li" class="my-1">
            <StepToReproduceCard
              :step="listItem"
              :steps_files="retest_files"
              :editSteps="editRetest"
              :gotoDelete="
                () => {
                  gotoRetestDelete(listItem.filename);
                }
              "
              :index="index + 1"
            />
          </b-list-group-item>
        </div>
        <b-card-code
          title="Add Screenshot"
          class="mt-1 card shadow-none bg-transparent border-primary"
        >
          <div class="">
            <!-- <div>
              <h4>Add Screenshot</h4>
              #default="{ invalid }"
            </div> -->
            <validation-observer
              ref="AddAttachmentForm1"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="addRetestEvidence"
              >
                <b-form-group label="Steps" label-for="description">
                  <template #label>
                    <span>Steps</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Steps"
                    vid="Steps"
                    rules="required"
                  >
                    <quill-editor
                      v-model="retestdescription"
                      :options="editorOptionRetest"
                    >
                      <div id="toolbarRetest" slot="toolbar">
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-list" value="ordered"></button>
                      </div>
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div
                  class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
                  v-if="attachment_file"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-100 mb-50"
                  >
                    <p class="font-weight-bold">Selected file:</p>
                    <b-button
                      @click="removeSelectedImageFromAddSteps"
                      variant="outline-danger"
                      size="sm"
                      ><feather-icon icon="XIcon" class="mr-50" /><span
                        class="align-middle"
                        >Remove Selected</span
                      ></b-button
                    >
                  </div>

                  <div class="w-100" style="overflow: hidden; height: 220px">
                    <panZoom
                      :options="{ minZoom: 0.5, maxScale: 5 }"
                      selector=".zoomable"
                    >
                      <div class="zoomable" style="cursor: move">
                        <img
                          height="100%"
                          width="100%"
                          :src="convertFileToImage(attachment_file)"
                          :alt="attachment_file.name"
                        />
                      </div>
                    </panZoom>
                  </div>
                  <p class="mt-25 font-weight-bold">
                    {{ attachment_file.name }}
                  </p>
                </div>
                <b-form-group label="Screenshot" label-for="File">
                  <template #label>
                    <span>Screenshot</span>
                    <!-- <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    > -->
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="file"
                    rules="ext:png,jpg,jpeg"
                  >
                    <div class="row">
                      <div class="col-8">
                        <b-form-file
                          v-model="attachment_file"
                          ref="file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          v-on:change="handleFileUpload()"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="col-4">
                        <b-button
                          block
                          @click="handlePasteImageToClipboardForAddStep"
                          variant="outline-primary"
                          ><feather-icon icon="CopyIcon" class="mr-50" /><span
                            class="align-middle"
                            >Paste from Clipboard</span
                          ></b-button
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Caption" label-for="caption">
                  <template #label>
                    <span>Caption</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Caption"
                    vid="caption"
                    rules="required"
                  >
                    <b-form-input
                      v-model="caption"
                      name="caption"
                      placeholder="A caption for the evidence"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="retestdescription == '' || invalid"
                  class="my-1"
                  size="sm"
                >
                  <span v-if="isloadingAttach"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>Attach</span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card-code>
      </b-tab>
      <b-tab title="Additional Evidences" lazy>
        <!-- <ul id="example-1">
          <li v-for="item in attached_files" :key="item.filename">
            {{ item.filename }}
          </li>
        </ul> -->
        <div v-for="listItem in attached_files" :key="listItem.filename">
          <b-list-group-item tag="li" class="my-1">
            <div class="row w-100">
              <!-- <b-avatar :text="listItem.filename.slice(0,2)" /> -->
              <div
                class="col-12 ml-25 w-100"
                style="max-height: 100px; overflow-y: auto"
              >
                <!-- <b-form-textarea
                  class="border-0 text-primary bg-transparent font-weight-bold"
                  v-model="listItem.description"
                  :style="{
                    height:
                      calculateTextareaHeight(listItem.description) + 'px',
                  }"
                  disabled
                ></b-form-textarea> -->
                <h5
                  class="border-0 text-primary bg-transparent font-weight-bold"
                  v-html="listItem.description"
                ></h5>
              </div>
              <div class="col-12 d-flex justify-content-between">
                <b-card-text class="font-weight-bold mb-0 ml-1">
                  {{ listItem.filename }}
                </b-card-text>
                <small class="d-flex justify-content-end">
                  <b-button
                    size="sm mr-1"
                    variant="danger"
                    @click="gotoAttachmentDelete(listItem.filename)"
                  >
                    Delete
                  </b-button>
                  <b-button
                    size="sm mr-1"
                    variant="info"
                    @click="viewAditionalAttachment(listItem.filename)"
                  >
                    <span
                      v-if="viewloader && listItem.filename == viewAditional"
                      >Please wait</span
                    >
                    <span v-else>View</span>
                  </b-button>
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="downloadAditionalAttachment(listItem.filename)"
                  >
                    <span
                      v-if="isdownloader && listItem.filename == fileAditional"
                      >Please wait</span
                    >
                    <span v-else>Download</span>
                  </b-button>
                </small>
              </div>
            </div>
          </b-list-group-item>
        </div>
        <!-- <b-list-group-item v-if="loading">
          <div class="d-flex justify-content-center mb-1">
            <b-spinner class="float-right" label="Floated Right" />
          </div>
        </b-list-group-item> -->
        <b-card-code
          class="mt-1 card shadow-none bg-transparent border-primary"
          title="Preview"
          v-if="image_data || video_data || isviewloading"
        >
          <div class="d-flex justify-content-center mb-1" v-if="isviewloading">
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <img v-bind:src="image_data" v-if="image_data" style="width: 100%" />
          <video width="100%" height="auto" v-if="video_data" controls>
            <source v-bind:src="video_data" type="video/mp4" />
          </video>
        </b-card-code>

        <b-card-code
          title="Add Screenshot"
          class="mt-1 card shadow-none bg-transparent border-primary"
        >
          <div class="">
            <validation-observer
              ref="AddAttachmentForm3"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="addAdditionalAttachment"
              >
                <b-form-group label="Steps" label-for="description">
                  <template #label>
                    <span>Steps</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Steps"
                    vid="Steps"
                    rules="required"
                  >
                    <quill-editor
                      v-model="additionaldescription"
                      :options="editorOptionAdditional"
                    >
                      <div id="toolbarAdditional" slot="toolbar">
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-list" value="ordered"></button>
                      </div>
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Screenshot" label-for="File">
                  <template #label>
                    <span>Screenshot</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="file"
                    rules="required"
                  >
                  <b-form-file
                    v-model="attachment_file"
                    :state="Boolean(attachment_file)"
                    ref="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    v-on:change="handleFileUpload()"
                  ></b-form-file>
                  <div class="mt-3">
                    Selected file:
                    {{ attachment_file ? attachment_file.name : "" }}
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Caption" label-for="caption">
                  <template #label>
                    <span>Caption</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Caption"
                    vid="caption"
                    rules="required"
                  >
                    <b-form-input
                      v-model="caption"
                      name="caption"
                      placeholder="A caption for the evidence"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- <b-form-group>
                  <b-form-checkbox
                    id="checkbox-10"
                    name="checkbox-10"
                    :checked="retestedEvidence"
                    v-model="retestedEvidence"
                  >
                    Retest Evidences
                  </b-form-checkbox>
                </b-form-group> -->
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="additionaldescription == '' || invalid"
                  class="my-1"
                  size="sm"
                >
                  <span v-if="isloadingAttach"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>Attach</span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card-code>
      </b-tab>
      <b-tab title="Logs" lazy>
        <LogView :vuln_id="vuln_id" :activeTab="1" />
      </b-tab>
    </b-tabs>
    <!-- <b-modal ref="modal-retest-scan" title="Edit Retest File" hide-footer>
      <div class="">
        <validation-observer ref="AddAttachmentForm1" #default="{ invalid }">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="addRetestEvidence"
          >
            <b-form-group label="Steps" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="Description"
                vid="description"
                rules="required"
              >
                <b-form-textarea
                  name="description"
                  v-model="description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="A brief description about the evidence"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Screenshot" label-for="File">
              <b-form-file
                v-model="attachment_file"
                :state="Boolean(attachment_file)"
                ref="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                v-on:change="handleFileUpload()"
              ></b-form-file>
              <div class="mt-3">
                Selected file:
                {{ attachment_file ? attachment_file.name : "" }}
              </div>
            </b-form-group>
            <b-form-group label="Caption" label-for="caption">
              <validation-provider
                #default="{ errors }"
                name="Caption"
                vid="caption"
                rules="required"
              >
                <b-form-input
                  v-model="caption"
                  :state="errors.length > 0 ? false : null"
                  name="caption"
                  placeholder="A caption for the evidence"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="submit" variant="primary" :disabled="invalid">
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal> -->
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ stepsfilename }}?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteStepsAttachment()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>

    <!-- retest modal -->
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteRetestModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ retestfilename }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteRetestAttachment()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <!-- attachment modal -->
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteAttachmentModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ attachmentfilename }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAttachment()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BListGroupItem,
  BCardText,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LogView from "./components/LogView.vue";
import draggable from "vuedraggable";
import ResponseMixins from "@/mixins/ResponseMixins";
import StepToReproduceCard from "./components/StepToReproduceCard.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BListGroupItem,
    BCardText,
    LogView,
    BSpinner,
    vSelect,
    draggable,
    StepToReproduceCard,
    BModal,
    quillEditor,
  },
  mixins: [ResponseMixins],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOptionSynopsis: {
        modules: {
          toolbar: "#toolbarSynopsis",
        },
      },
      editorOptionRecommendation: {
        modules: {
          toolbar: "#toolbarRecommendation",
        },
      },
      editorOptionReferences: {
        modules: {
          toolbar: "#toolbarReferences",
        },
      },
      editorOptionEvidence: {
        modules: {
          toolbar: "#toolbarEvidence",
        },
      },
      editorOptionSteps: {
        placeholder: "A brief description about the evidence",
        modules: {
          toolbar: "#toolbarSteps",
        },
      },
      editorOptionEditSteps: {
        placeholder: "A brief description about the evidence",
        modules: {
          toolbar: "#toolbarEditSteps",
        },
      },
      editorOptionRetest: {
        placeholder: "A brief description about the evidence",
        modules: {
          toolbar: "#toolbarRetest",
        },
      },
      editorOptionEditRetest: {
        placeholder: "A brief description about the evidence",
        modules: {
          toolbar: "#toolbarEditRetest",
        },
      },
      editorOptionAdditional: {
        placeholder: "A brief description about the evidence",
        modules: {
          toolbar: "#toolbarAdditional",
        },
      },
      selectedStep: null,
      selectedRetestStep: null,
      is_staff: this.$store.state.app.user.is_staff,
      vuln_id: parseInt(this.$route.params.id),
      organization: "",
      organizations: [{ value: null, text: "--Select Organization--" }],
      assets: [{ value: null, text: "--Select Asset--" }],
      owasp_options: [],
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      protocol_options: [
        { value: "-", text: "-" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      form: {
        name: "",
        severity: null,
        asset_id: this.$route.params.id,
        origin: "",
        path: "-",
        port: "-",
        description: "",
        caption: "",
        synopsis: "",
        recommendation: "",
        nessus_evidence: "",
        is_exploited: false,
        // is_verified: false,
        references: "",
        cvss_score: "",
        owasp_categories: "",
        cve: "",
        parameter: "",
        verified_by: this.$store.state.app.user.email,
        protocol: "-",
      },
      attachment_file: null,
      description: "",
      retestdescription: "",
      additionaldescription: "",
      attached_files: [],
      steps_files: {}, //Object to store image files
      steps_to_reproduce: [], // Array to store parsed evidenec details
      retest_evidence: [], // Array to store parsed evidenec details
      retest_files: [], //Object to store image files
      loading: false,
      loader: "",
      viewloader: "",
      retestedEvidence: true,
      // nessus_evidence: "",

      isEditable: false,
      isStepsEditable: false,
      fileRetest: "",
      fileSteps: "",
      orderId: "",
      fileAditional: "",
      viewAditional: "",
      attachment_retest_file: "",
      attachment_steps_file: "",

      isloading: false,
      isdownloader: false,
      isviewloading: false,
      isloadingAttach: false,
      isloadingUpdate: false,

      images: {},
      imageSrc: null,

      openDeleteModal: false,
      stepstitle: "",
      stepsorder_id: "",
      stepsfilename: "",

      retestfilename: "",
      openDeleteRetestModal: false,

      attachmentfilename: "",
      openDeleteAttachmentModal: false,
      disable: false,
    };
  },

  created: function () {
    this.load();
  },
  computed: {
    keymap() {
      return {
        "ctrl+v": this.handlePasteKeyPress,
      };
    },
  },
  methods: {
    async handlePasteImageToClipboardForEditStep() {
      this.attachment_steps_file = await this.getImageFromClipboard();
    },

    handlePasteImageToClipboardForAddStep() {
      this.pasteImage();
    },

    removeSelectedImageFromAddSteps() {
      this.attachment_file = null;
    },

    handlePasteKeyPress() {
      this.pasteImage();
    },

    showEditRetestStepModal() {
      this.$refs["edit_retest_step_modal"].show();
    },
    hideEditRetestStepModal() {
      this.$refs["edit_retest_step_modal"].hide();
    },

    removeSelectedImage() {
      this.attachment_steps_file = "";
    },

    blobToFile(theBlob, fileName) {
      // theBlob.lastModified = new Date();
      // theBlob.name = fileName;
      // return theBlob;
      const myFile = new File([theBlob], fileName, {
        type: theBlob.type,
      });
      return myFile;
    },

    async getImageFromClipboard() {
      const auth = await navigator.permissions.query({
        name: "clipboard-read",
      });
      if (auth.state !== "denied") {
        const item_list = await navigator.clipboard.read();
        let image_type; // we will feed this later
        const item = item_list.find(
          (
            item // choose the one item holding our image
          ) =>
            item.types.some((type) => {
              // does this item have our type
              if (type.startsWith("image/")) {
                image_type = type; // store which kind of image type it is
                return true;
              }
            })
        );
        const file = item && (await item.getType(image_type));
        if (file && file !== null && typeof file != "undefined") {
          return this.blobToFile(file, "Screenshot.png");
        }
        console.log("File", file);
      }
    },

    async pasteImage() {
      const auth = await navigator.permissions.query({
        name: "clipboard-read",
      });
      if (auth.state !== "denied") {
        const item_list = await navigator.clipboard.read();
        let image_type; // we will feed this later
        const item = item_list.find(
          (
            item // choose the one item holding our image
          ) =>
            item.types.some((type) => {
              // does this item have our type
              if (type.startsWith("image/")) {
                image_type = type; // store which kind of image type it is
                return true;
              }
            })
        );
        const file = item && (await item.getType(image_type));
        if (file && file !== null && typeof file != "undefined") {
          let screenshot = this.blobToFile(file, "Screenshot.png");
          this.attachment_steps_file = screenshot;
          this.attachment_file = screenshot;
        }
        console.log("File", file);
      }
    },

    resetSelectedImages() {
      this.attachment_steps_file = "";
      this.attachment_file = null;
    },

    convertFileToImage(file) {
      if (file) {
        return URL.createObjectURL(file);
      }
    },

    showEditStepModal() {
      this.$refs["edit_step_modal"].show();
    },
    hideEditStepModal() {
      this.$refs["edit_step_modal"].hide();
    },

    reorderStepsToReproduce(deletedOrderId) {
      let firstHalf = this.steps_to_reproduce;
      let secondHalf = firstHalf
        .splice(deletedOrderId - 1)
        .filter((data) => data.order_id != deletedOrderId)
        .map((data) => {
          data.order_id = data.order_id - 1;
          return data;
        });
      let new_steps_to_reproduce = firstHalf.concat(secondHalf);
      this.updateStepsToReproduce(new_steps_to_reproduce)
        .then((res) => {
          // this.handleResponse(res)
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    updateStepsToReproduce(data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "PATCH",
          url:
            process.env.VUE_APP_BASEURL +
            "asset/vulns/" +
            this.$route.params.id +
            "/update-order-of-evidences/",
          data: { data: data },
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    handleDetectDrag: function (e) {
      // console.log('Dragged Item:', e)
      // console.log('Old Steps To Reproduce', this.steps_to_reproduce)
      const newData = this.steps_to_reproduce.map((step, i) => {
        step.order_id = i + 1;
        return step;
      });
      console.log("New Steps To Reproduce", newData);
      this.updateStepsToReproduce(newData)
        .then((res) => {
          // this.handleResponse(res)
          this.steps_to_reproduce = newData;
          // this.load()
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    calculateTextareaHeight(content) {
      const lineHeight = 35; // adjust as needed to match your CSS
      const lines = content.split(/\r\n|\r|\n/);
      const height = lines.length * lineHeight;
      return height;
    },
    viewAttachment(filename) {
      const options = {
        method: "GET",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/vuln-details/",
        responseType: "bolb",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(
          res.data,
          "res imagehhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
        );
        this.image_data = window.URL.createObjectURL(res.data);
        const base64 = new TextEncoder().encode(response.data);
        this.imageSrc = `data:image/jpeg;base64,${base64}`;
      });
    },
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/vulns/" + this.$route.params.id, //+
        // "/vuln-details/",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        self.form = res.data;
        console.log(self.form, "fffffffffffffffffffffjjj");
        if (res.data.additional_evidence) {
          var f_data = JSON.parse(res.data.additional_evidence);
          console.log(f_data, "filr data ");
          // f_data.map(function(filename, filedetails) {
          self.attached_files = f_data;
          Object.keys(f_data).map(function (filename, filedetails) {
            console.log("filename", filename);
          });
          //})
        }
        if (res.data.steps_to_reproduce) {
          var f_data = JSON.parse(res.data.steps_to_reproduce);
          // f_data.map(function(filename, filedetails) {
          const new_steps_to_reproduce = [];
          Object.values(f_data).map((data) => {
            new_steps_to_reproduce[data.order_id - 1] = data;
          });
          self.steps_to_reproduce = new_steps_to_reproduce;
          Object.keys(f_data).map((filename) => {
            this.downloadAttachment(filename, "steps_to_reproduce");
          });
        }
        if (res.data.retest_evidence) {
          var f_data = JSON.parse(res.data.retest_evidence);
          console.log(f_data, "retest_evidence");
          // f_data.map(function(filename, filedetails) {
          this.retest_evidence = Object.values(f_data);
          Object.keys(f_data).map((filename) => {
            console.log("Download", filename);
            this.downloadAttachment(filename, "retest_evidence");
          });
          //})
        }

        //var data = JSON.parse(res.data);
        // data.map(function (key, value) {
        //   self.form = key["details"];
        //   console.log(key["details"]);
        //   if (key["details"]["additional_evidence"]) {
        //     var f_data = JSON.parse(key["details"]["additional_evidence"]);
        //     console.log(f_data);
        //     // f_data.map(function(filename, filedetails) {
        //     self.attached_files = f_data;
        //     Object.keys(f_data).map(function (filename, filedetails) {
        //       console.log("filenam", filename);
        //     });
        //   }
        // });
      });
    },
    onOrgChange: function () {
      this.assets = [];
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        res.data.map(function (value, key) {
          let a = {
            value: res.data[key].asset_id,
            text: res.data[key].asset_nickname,
          };
          // console.log(res.data[key].profile.organization)
          self.assets.push(a);
        });
      });
    },

    updateVulnerability: function () {
      this.$refs.VulnUpdateForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          var self = this;
          self.form.verified_by = this.$store.state.app.user.email;
          console.log(self.form);
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: self.form,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/vulns/" +
              this.$route.params.id +
              "/update-full/",
          };
          this.$http(options).then((res) => {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully updated vulnerability !",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            // }
          });
        }
      });
    },

    updateEvidence: function () {
      // this.$refs.VulnUpdateForm.validate().then((success) => {
      // if (success) {
      //call to axios
      this.isloading = true;
      this.disable = true;
      var self = this;
      // self.form.verified_by = this.$store.state.app.user.email;
      console.log(self.form, "list form");
      let data = {
        nessus_evidence: self.form.nessus_evidence,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/",
      };
      this.$http(options).then((res) => {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated Evidence Note",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.isloading = false;
        this.disable = false;
        // this.refreshEvidence()
        // }
      });
      // }
      // });
    },
    // refreshEvidence(){
    //   this.form.evidence = ''
    // },
    handleFileUpload() {
      this.attachment_file = this.$refs.file.files[0];
    },
    addAttachment() {
      this.$refs.AddAttachmentForm.validate().then((success) => {
        this.loading = true;
        this.isloadingAttach = true;
        if (success) {
          let formData = new FormData();
          formData.append("file", this.attachment_file);
          formData.append("title", this.description);
          formData.append("caption", this.caption);
          formData.append("order_id", this.steps_to_reproduce.length + 1);
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/vulns/" +
              this.$route.params.id +
              "/add-steps-to-reproduce-evidences/",
            // url:
            //   process.env.VUE_APP_BASEURL +
            //   "asset/vulns/" +
            //   this.$route.params.id +
            //   "/add-attachment/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.load();
            self.caption = "";
            self.$nextTick(() => this.$refs.AddAttachmentForm.reset());
            // self.orderId= "";
            if (res.data.status == "error") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added the attachment! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }

            this.loading = false;
            this.attachment_file = null;
            this.description = "";
            this.isloadingAttach = false;
          });
        }
      });
    },
    addRetestEvidence() {
      this.$refs.AddAttachmentForm1.validate().then((success) => {
        this.loading = true;
        this.isloadingAttach = true;
        if (success) {
          let formData = new FormData();
          formData.append("file", this.attachment_file);
          formData.append("description", this.retestdescription);
          formData.append("caption", this.caption);
          // formData.append("is_retested", this.retestedEvidence);
          // formData.append("evidence_note", this.retest_evidence_note);
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/vulns/" +
              this.$route.params.id +
              "/add-retest-evidence/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.load();
            if (res.data.status == "error") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added the attachment! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
            this.isloadingAttach = false;
            this.loading = false;
            this.attachment_file = null;
            this.retestdescription = "";
            this.caption = "";
            this.$nextTick(() => this.$refs.AddAttachmentForm1.reset());
          });
        }
      });
    },
    addAdditionalAttachment() {
      this.$refs.AddAttachmentForm3.validate().then((success) => {
        this.loading = true;
        this.isloadingAttach = true;
        if (success) {
          let formData = new FormData();
          formData.append("file", this.attachment_file);
          formData.append("description", this.additionaldescription);
          formData.append("caption", this.caption);
          // formData.append("is_retested", this.retestedEvidence);
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            // url: process.env.VUE_APP_BASEURL + "asset/vulns/" + this.$route.params.id + "/add-attachment/"
            url:
              process.env.VUE_APP_BASEURL +
              "asset/vulns/" +
              this.$route.params.id +
              "/add-attachment/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.load();
            if (res.data.status == "error") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added the attachment! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }

            this.isloadingAttach = false;
            this.loading = false;
            this.attachment_file = null;
            this.additionaldescription = "";
            this.caption = "";
            this.$nextTick(() => this.$refs.AddAttachmentForm3.reset());
          });
        }
      });
    },
    editSteps(step) {
      console.log("Edit Step", step);
      this.selectedStep = {
        ...step,
      };
      // this.pasteImage()
      this.showEditStepModal();
      this.isStepsEditable = true;
      this.fileSteps = step.filename;
      this.resetSelectedImages();
    },
    editRetest(step) {
      this.selectedRetestStep = { ...step };
      // this.pasteImage()
      this.showEditRetestStepModal();
      this.isEditable = true;
      this.fileRetest = step.filename;
      this.resetSelectedImages();
    },

    // editRetest(filename) {
    //   this.isEditable = true;
    //   this.fileRetest = filename;
    // },
    updateStepsAttachment(listStepsItem) {
      this.isloadingUpdate = true;
      let formData = new FormData();
      console.log(formData, "formadata");
      if (
        this.attachment_steps_file == "" ||
        this.attachment_steps_file == null
      ) {
        formData.append("filename", listStepsItem.filename);
        formData.append("title", listStepsItem.title);
        formData.append("caption", listStepsItem.caption);
        formData.append("order_id", listStepsItem.order_id);
        formData.append("old_filename", this.attachment_steps_file);
      } else {
        formData.append("old_filename", listStepsItem.filename);
        formData.append("title", listStepsItem.title);
        formData.append("caption", listStepsItem.caption);
        formData.append("order_id", listStepsItem.order_id);
        formData.append("filename", this.attachment_steps_file);
      }
      const options = {
        method: "PATCH",
        data: formData,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/update-steps-to-reproduce-evidences/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          this.load();
          this.handleResponse(res, "Step Updated");
          this.isloadingUpdate = false;
          this.loading = false;
          this.isStepsEditable = false;
          this.selectedStep = null;
          this.hideEditStepModal();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    updateRetestAttachment(listItem) {
      this.isloadingUpdate = true;
      let formData = new FormData();
      console.log(formData, "retest formadata");
      if (this.attachment_steps_file == "") {
        formData.append("filename", listItem.filename);
        formData.append("description", listItem.description);
        formData.append("caption", listItem.caption);
        formData.append("old_filename", this.attachment_steps_file);
      } else {
        formData.append("old_filename", listItem.filename);
        formData.append("description", listItem.description);
        formData.append("caption", listItem.caption);
        formData.append("filename", this.attachment_steps_file);
      }

      const options = {
        method: "PATCH",
        data: formData,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/update-retest-evidence/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.hideEditRetestStepModal();
        this.load();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully Updated the attachment! ",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.isloadingUpdate = false;
        this.loading = false;
        this.isEditable = false;
      });
    },
    gotoDelete(filename, order_id, title) {
      this.openDeleteModal = true;
      this.stepsfilename = filename;
      this.stepsorder_id = order_id;
      this.stepstitle = title;
    },
    deleteStepsAttachment() {
      // if (confirm("Do you really want to delete?")) {
      this.loading = true;
      const options = {
        method: "DELETE",
        data: {
          filename: this.stepsfilename,
          order_id: this.stepsorder_id,
          title: this.stepstitle,
        },
        // data: { filename: filename, order_id: order_id, title: title },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/delete-steps-to-reproduce-evidences/",
      };
      var self = this;
      this.$http(options).then((res) => {
        let newSteptoReproduce = this.steps_to_reproduce.filter(
          (step) => step.order_id != this.stepsorder_id
        );
        this.steps_to_reproduce = newSteptoReproduce;
        if (this.steps_to_reproduce.length > 0) {
          this.reorderStepsToReproduce(this.stepsorder_id);
        }
        this.openDeleteModal = false;
        // this.load();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted the attachment! ",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.loading = false;
      });
    },
    gotoAttachmentDelete(filename) {
      this.openDeleteAttachmentModal = true;
      this.attachmentfilename = filename;
    },
    deleteAttachment() {
      // if (confirm("Do you really want to delete?")) {
      this.loading = true;
      const options = {
        method: "DELETE",
        data: { filename: this.attachmentfilename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/delete-attachment/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.openDeleteAttachmentModal = false;
        this.load();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted the attachment! ",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.loading = false;
      });
      // }
    },

    gotoRetestDelete(filename) {
      this.openDeleteRetestModal = true;
      this.retestfilename = filename;
    },
    deleteRetestAttachment() {
      // if (confirm("Do you really want to delete?")) {
      this.loading = true;
      const options = {
        method: "DELETE",
        data: { filename: this.retestfilename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/delete-retest-evidence/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.openDeleteRetestModal = false;
        this.load();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted the attachment! ",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.loading = false;
      });
      // }
    },
    downloadAttachment(filename, key) {
      this.loading = true;
      this.loader = true;
      console.log("Key", key);
      var url;
      if (!filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/";

      if (key == "steps_to_reproduce" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-steps-to-reproduce-evidences/";

      if (key == "retest_evidence" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-retest_evidence/";

      const options = {
        method: "POST",
        data: { filename: filename },
        url: url,
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // this.images.filename = url;
        // this.$refs[filename].src = url
        if (key == "steps_to_reproduce")
          this.$set(self.steps_files, filename, url);
        console.log("Step Files", this.steps_files);
        if (key == "retest_evidence")
          this.$set(self.retest_files, filename, url);
        console.log("Retest Files", this.steps_files);

        // self.images.push({[filename]:url})
        // this.loading = false;
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", filename); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        // this.loader = false;
      });
    },
    downloadAditionalAttachment(filename) {
      this.isviewloading = true;
      this.isdownloader = true;
      this.fileAditional = filename;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.isviewloading = false;
        this.isdownloader = false;
      });
    },

    viewAditionalAttachment(filename) {
      this.isviewloading = true;
      this.viewloader = true;
      this.viewAditional = filename;
      this.video_data = this.image_data = false;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        //this.image_data = new Blob([res.data])
        var ext = filename.split(".").pop();
        console.log(ext);
        if (ext == "mp4")
          this.video_data = window.URL.createObjectURL(new Blob([res.data]));
        else if (ext == "jpeg" || ext == "jpg" || ext == "png")
          this.image_data = window.URL.createObjectURL(new Blob([res.data]));
        else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        this.isviewloading = false;
        this.viewloader = false;
      });
    },
    fetchOwaspOptions(search, loading) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/owasp-search?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: value.title,
              text: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);
          });
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.b-form-textarea {
  resize: vertical !important;
}

[dir] .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: rgba(245, 231, 231, 0);
  border-radius: 5px !important;
  border: 2px solid rgb(45 95 138 / 13%);
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  border-radius: 5px !important;
  background-color: #05050500 !important;
  border: 2px solid rgb(45 95 138 / 13%);
}

.list-group-item {
  transition: all 0.6s;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>





